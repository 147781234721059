import Vue from "vue";
import VueRouter from "vue-router";
import {
  system,
  getRemainTime
} from '@/axios/api';
import Store from '@/store/moudles/user'
import home from '@/views/home/index.vue'
import axios from 'axios';
import { microParams } from '../micro.js';

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
const routes = [{
    path: '/',
    name: 'index',
  component: () => import('@/views/login'),
  // component: home,
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import('@/views/login'),
  // },
  {
    path: "/fileUrl",
    name: "FileUrl",
    component: () => import('@/views/registerPage/fileUrl.vue'),
  },
  {
    path: "/success",
    name: "Success",
    component: () => import('@/views/registerPage/success.vue'),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import('@/views/registerPage/register.vue'),
  },
  {
    path: "/PostgreSQL",
    name: "PostgreSQL",
    component: () => import('@/views/registerPage/PostgreSQL.vue'),
  },
  {
    path: "/mongdb",
    name: "Mongdb",
    component: () => import('@/views/registerPage/mongdbInstall.vue'),
  },
  {
    path: "/share",
    name: "Share",
    component: () => import('@/views/registerPage/share.vue'),
  },
  {
    path: '/expire',
    name: 'Expire',
    component: () => import('@/views/expire/expire.vue'),
  },
  {
    path: '/declaration',
    name: 'Declaration',
    component: () => import('@/views/declaration/declaration.vue')
  },
];

const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  // base:'/',
  routes,
});

const subRouterBase = microParams.map(({ props }) => props.routerBase); //获取所有子应用的基础路由
const isChildrenRoute = (path) => subRouterBase.some(item => path.startsWith(item))

let count = false;
const firstUserGoto = (to, from, next) => {
  if (to.path.indexOf('/register') !== -1 || to.path.indexOf('/share') !== -1 || to.path.indexOf('/mongdb') !== -1 || to.path.indexOf('/PostgreSQL') !== -1 || to.path.indexOf('/success') !== -1 || to.path.indexOf('fileUrl') !== -1) {
    next();
  } else {
    router.push('/register');
  }
}
router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('TokenKey');
  //从子项目跳转到主项目
  //如果当前路由是子应用路由的跳转，直接放行
  if (isChildrenRoute(from.path) && isChildrenRoute(to.path)) {
    if(localStorage.getItem('system') == 'true') {
      if(!localStorage.getItem('userInfo')) {
        process.env.NODE_ENV === 'development' ? next('/') : window.open("/freexserver")
      } else {
        next()
      }
    } else {
      next('/expire')
    }
    
  } else {
    //是否注册
    axios.get(`/freexserver/dataserver/query/system/user`).then(result => {
      let res = result.data;
      if (res.items) {
        localStorage.setItem('system', true);
        axios.get('/freexserver/check/systime').then(result => {
          let data = result.data;
          if (data && (data.items == '系统试用期不足3天' || data.items == '系统试用期不足7天')) {
            if (to.path == '/expire' || to.path == '/register' || to.path == '/share' || to.path == '/mongdb' || to.path == '/PostgreSQL' || to.path == '/success' || to.path == '/fileUrl') {
              next()
            } else if (to.path != '/expire' && !count) {
              if (isChildrenRoute(to.path)) {
                if (token) {
                  next(to.path);
                } else {
                  next('/login');
                }

              } else {
                if (!isChildrenRoute(from.path) && !isChildrenRoute(to.path)) {
                  next('/expire')
                }
              }
              count = true;
            } else {
              next()
            }
          } else if (data && data.items == '系统试用期已到，请联系管理员') {
            if (to.path == '/expire' || to.path == '/register' || to.path == '/share' || to.path == '/mongdb' || to.path == '/PostgreSQL' || to.path == '/success' || to.path == '/fileUrl') {
              next()
            } else {
              next('/expire')
            }
          } else if (to.path.indexOf('/register') !== -1 || to.path.indexOf('/share') !== -1 || to.path.indexOf('/mongdb') !== -1 || to.path.indexOf('/PostgreSQL') !== -1 || to.path.indexOf('/success') !== -1 || to.path.indexOf('fileUrl') !== -1) {
            next()
          } else {
            let userName = localStorage.getItem('user') || sessionStorage.getItem('user');
            if (userName && token) {
              //已经登录了
              next()
            } else if (to.path == '/' || to.path == '/login') {
              localStorage.removeItem("userInfo");
              localStorage.removeItem('TokenKey');
              next()
            } else {
              localStorage.removeItem('TokenKey');
              localStorage.removeItem("userInfo");
              next('/login')
            }
          }
        })
      } else {
        localStorage.setItem('system', false);
        //未注册，第一次登陆
        firstUserGoto(to, from, next);
      }
    })

  }

  // next()
})


export default router;