import Vue from "vue";
import Vuex from "vuex";
import user from './moudles/user';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading:false,
  },
  getters: {},
  mutations: {
    loading(state,payload) {
      this.loading = payload
    }
  },
  actions: {},
  modules: {
    user
  },
});
