// 状态
const state = {
    token: localStorage.getItem('TokenKey') || '', // token 共享状态
    hasLogin: false,
    userPermisson: [],
    // share页信息
    share: {
        storageRemoteUrl: "",
        userName: "",
        password: "",
    },
    // mongdb页当前选择为: 1系统自带 2应用自由
    mongdbSelect: -1,
    // mongdb页系统自带信息
    mongdbForm: {
        hostIP: "",
        host: "",
        source: "",
        name: "",
        password: "",
    },
    // mongdb页应用自有信息
    mongdbConfigHost: "",
}

const getters = {

}

const mutations = {
    setToken(state, token) {
        state.token = token // 将数据设置给vuex
        // 同步给缓存

    },
    removeToken(state) {
        state.token = null // 将token的数据置空

    },
    // 更新share页信息
    changeShare(state, data) {
        state.share = data;
    },
    // 更新mongdb页当前选择
    changeMongdbSelect(state, data) {
        state.mongdbSelect = data;
    },
    // 更新mongdb页系统自带信息
    changeMongdbForm(state, data) {
        state.mongdbForm = data;
    },
    // 更新mongdb页应用自有信息
    changeMongdbConfigHost(state, data) {
        state.mongdbConfigHost = data;
    },
    hasLoginMutation(state, payload) {
        state.hasLogin = payload;
    },
}
const actions = {
    SET_TOKEN({commit},payload) {
        commit('setToken',payload);
    }
}
const userStore = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
export default userStore