import {
  registerMicroApps,
  start,
  initGlobalState,
  loadMicroApp
} from 'qiankun';
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/index.less";
import "./assets/css/index.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import {
  microParams
} from './micro'
Vue.use(ElementUI);
let loadingInstance;
const loader = () => {
  loadingInstance = ElementUI.Loading.service({
    fullscreen: true, // 是否全屏显示
    text: 'loading...', //loading显示文字
    background: `rgba(0,0,0,.7)`, //背景色
  })
  store.commit('loading', loadingInstance)
};
Vue.config.productionTip = false;

//注册子应用列表
registerMicroApps(microParams, {
  beforeLoad: () => {
    loader();
  },
  afterMount: () => {
    setTimeout(() => {
      loadingInstance.close();
    }, 1000)
  }
});

start({
  prefetch: true,
});
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#main");