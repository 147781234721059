import axios from "axios"
import {
    Message
} from 'element-ui'
import Store from '@/store'
axios.defaults.withCredentials = true;
axios.withCredentials = true;
const service = axios.create({
    // 设置默认前缀
    baseURL: "",
    // 请求超时时间
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json'
    }
})
service.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
// 请求拦截
service.interceptors.request.use(config => {
    if(Store.state.user.token) {
        config.headers['authorization'] = Store.state.user.token;
    }
    return config;
}, err => {
    return Promise.reject(err);
});

// 响应拦截
service.interceptors.response.use(response => {
    const statusCode = response.status;
    if (statusCode === 200) {
        if (response.headers['authorization']) {
            //获取响应头中的token
            let token = response.headers['authorization'];
            Store.dispatch('user/SET_TOKEN', token);
            localStorage.setItem('TokenKey',token);
            sessionStorage.setItem('TokenKey',token);
        }
        return Promise.resolve(response.data);
    } else if (statusCode === 501) {
        // 根据需求提示或跳转
        return Promise.resolve()
    } else {
        return Promise.reject();
    }
}, err => {
    // 报错提示
    if (err.code === 'ECONNABORTED') {
        Message({
            message: '请求超时，请稍后重试！',
            type: 'error',
            durations: 5 * 1000
        })
    }
    return Promise.reject(err);
});


// get
export const get = (url, params = {}, type, responseType = 'json') => {
    return new Promise((resolve, reject) => {
        service.get(url, {
            params: params,
            responseType: responseType
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
    })
}

// post
export const post = (url, params, type, timeout = 60000) => {
    return new Promise((resolve, reject) => {
        service.post(url, params, {
            headers: {
                "Content-Type": "application/json"
            },
            timeout,
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
    })
}